import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col, Typography } from "antd"

import Layout from "../../components/layout"
import Head from "../../components/head"
import BetaTestersLogos from "../../components/beta-testers-logos"
import IntegrationsLogos from "../../components/integrations-logos"
import CtaRow from "../../components/cta-row"
import FreeTrialCta from "../../components/free-trial-cta"

const { Title, Paragraph, Text } = Typography

const Integrations = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "integration-image-0.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      oneImage: file(relativePath: { eq: "anticiper-tresorerie-image-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      twoImage: file(relativePath: { eq: "anticiper-tresorerie-image-4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      threeImage: file(relativePath: { eq: "suivi-tresorerie-image-3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fourImage: file(relativePath: { eq: "anticiper-tresorerie-image-6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fiveImage: file(relativePath: { eq: "anticiper-tresorerie-image-5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      florentImage: file(relativePath: { eq: "florent-martin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      bastienTriclotImage: file(relativePath: { eq: "bastien-triclot.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      laNoteParisienneImage: file(relativePath: { eq: "lanoteparisienne-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 40)
        }
      }
      zozioImage: file(relativePath: { eq: "logo-zozio.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const description = "Intégrez votre outil de facturation, logiciel comptable ou ERP avec le logiciel de trésorerie RocketChart pour anticipez facilement votre trésorerie et automatiser le rapprochement bancaire."

  return (
    <Layout>
      <Head
        title="Integrations"
        description={description}
        canonical="https://rocketchart.co/fonctionnalites/integrations-logiciels-comptable-facturation-erp/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 13, offset: 0, order: 0 }}>
          <Title>Intégrez vos outils de facturation pour centraliser les encours dans votre trésorerie</Title>
          <Paragraph style={{ color: "black" }}>Facilitez votre gestion en connectant simplement votre ERP, outil de facturation ou de comptabilité. Centralisez vos données et créez des prévisions automatiques.</Paragraph>

          <CtaRow />
        </Col>
        <Col xs={{ span: 16, offset: 4, order: 0 }} lg={{ span: 11, offset: 0, order: 1 }} justify="center">
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="Intégration outil de facturation et comptable avec RocketChart" className="rounded-image" />
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: "50px" }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils pilotent leur trésorerie avec RocketChart</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.oneImage.childImageSharp.gatsbyImageData} alt="Alimentez vos prévisions de trésorerie à partir des données de facturation" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Créez votre prévisionnel à partir de vos encours de facturation</Title>
          <Paragraph style={{ color: "black" }}>Alimentez vos prévisions automatiquement et anticipez votre trésorerie dans les prochaines semaines. Prenez des décisions éclairées par des données fiables et sans erreurs.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Gagnez en fiabilité sur vos projections à court terme</Title>
          <Paragraph style={{ color: "black" }}>Enrichissez votre prévisionnel de trésorerie avec les informations de vos outils de gestion. Précisez une date de paiement attendu sur vos factures pour générer des projections précises.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.twoImage.childImageSharp.gatsbyImageData} alt="Prévisionnel de trésorerie fiable et automatisé" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.threeImage.childImageSharp.gatsbyImageData} alt="Rapprochement banque / facture semi-automatisé" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Ne jonglez plus entre différents outils de suivi</Title>
          <Paragraph style={{ color: "black" }}>Centraliser le suivi des paiements et suivez vos impayés avec un rapprochement bancaire semi-automatisé. Mesurez l’impact des retards de paiement sur votre trésorerie.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Visualisation flexible de vos projections de trésorerie</Title>
          <Paragraph style={{ color: "black" }}>Personnalisez vos graphiques et l’échelle de suivi pour coller à votre besoin. Pilotez votre trésorerie au mois, à la semaine ou au jour près. Optez pour une gestion plus ou moins fine.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.fourImage.childImageSharp.gatsbyImageData} alt="Visualisation flexible de votre trésorerie" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.fiveImage.childImageSharp.gatsbyImageData} alt="Comparaison du facturé avec le budgetet et le réalisé" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Comparez votre Budget vs Facturé vs Réalisé en temps réel</Title>
          <Paragraph style={{ color: "black" }}>Contrôlez en temps réel vos prévisions budgétaires par raport à votre facturation en cours et le réalisé bancaire. Évaluez votre performance par rapport aux objectifs fixés lors de la réalisation des budgets.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px", marginBottom: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col span={12} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Intégrez vos outils de facturation, ERP et logiciels de comptabilité</Title>
          <Paragraph style={{ color: "black" }}>Synchronisez en temps réel vos factures dans RocketChart pour générer automatiquement votre prévisionnel de trésorerie et automatiser le rapprochement banque / facture.</Paragraph>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <IntegrationsLogos />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center" style={{ marginBottom: "60px" }}>
        <Col xs={{ span: 24 }} align='center'>
          <Title level={2}>Ils adorent RocketChart</Title>
        </Col>
        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <GatsbyImage image={data.laNoteParisienneImage.childImageSharp.gatsbyImageData} alt="logo La Note Parisienne" />
            </div>
            <Title level={4}>« RocketChart nous a permis de maîtriser notre trésorerie de À à Z. L’outil est très intuitif, simple et complet. Le support est toujours présent sur le chatbot pour un accompagnement quotidien. De belles perspectives d’avenir pour cette solution en constante évolution. Je recommande ! »</Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <GatsbyImage image={data.florentImage.childImageSharp.gatsbyImageData} alt="Florent Martin - Président de La Note Parisienne" />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
                <Text strong>Florent Martin</Text>
                <Text>Président - La Note Parisienne</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <GatsbyImage image={data.zozioImage.childImageSharp.gatsbyImageData} alt="logo Zozio" />
            </div>
            <Title level={4}>« Avec Excel nous étions incapables de gérer notre trésorerie proprement. Avec RocketChart, nous sommes désormais capables de piloter en temps réel notre trésorerie, de projeter différents scenarii et de prévenir d'éventuels problèmes. Un outil rapidement déployé, efficace et ergonomique ! »</Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <GatsbyImage image={data.bastienTriclotImage.childImageSharp.gatsbyImageData} alt="Bastien Triclot - CEO & Fondateur de Zozio" />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
                <Text strong>Bastien Triclot</Text>
                <Text>CEO & Fondateur - Zozio</Text>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "0px", marginBottom: "60px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Démarrez maintenant votre essai gratuit</Title>
          <FreeTrialCta />
        </Col>
      </Row>

    </Layout>
  )
}

export default Integrations